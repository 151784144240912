import ru from "../locale/ru.json";
import en from "../locale/en.json";

export async function loadLocale() {
  if (!window.easehow) {
    window.easehow = {};
  }
  // if (currentLocale) {
  //   window.easehow.currentLocale = currentLocale;
  // }

  window.easehow.locale = {
    ru: ru,
    en: en,
  };

  return true;
}
