import React, { useEffect, useState } from "react";
import ButtonService from "./ButtonService";

import { useNavigate } from "react-router-dom";
import styles from "../styles/ButtonServiceList.module.css";

export default function ButtonServiceList({ serviceList, tagList }) {
  const navigate = useNavigate();
  let lang = window.easehow.currentLocale;

  return (
    // <div className="service_button_list">
    <nav className={styles.service_button_list}>
      {Object.keys(serviceList).map((key) => (
        <ButtonService
          key={key}
          name={serviceList[key][lang.code]}
          onClick={() => {
            navigate("/list", {
              state: {
                serviceName: key,
                displayName: serviceList[key],
                tagList: tagList,
              },
            });
          }}
        />
      ))}
    </nav>
  );
}
