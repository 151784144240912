import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/NavBar.module.css";
import { sendAnalytics } from "../functions/api/sendAnalytics";
import { AnalyticsData } from "../classes/AnalyticsData";
import { textBack, textProfile } from "../consts/ConstSevicePage";

export default function NavBar({
  currentPagePath,
  currentPathState,
  prevPagePath,
  prevPageState,
}) {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;
  const navigate = useNavigate();

  console.log({
    currentPagePath: currentPagePath,
    currentPathState: currentPathState,
    prevPagePath: prevPagePath,
    prevPageState: prevPageState,
  });

  function ProfileButton({}) {
    return (
      <button
        onClick={() => {
          navigate("/profile", {
            state: {
              prevPath: currentPagePath,
              prevState: currentPathState,
            },
          });
        }}
      >
        {locale[lang.code].navBar1}
      </button>
    );
  }

  function BackButton({}) {
    return (
      <button
        onClick={() => {
          navigate(prevPagePath, { state: prevPageState ?? {} });
        }}
      >
        {locale[lang.code].navBar2}
      </button>
    );
  }

  return (
    <nav className={styles.nav_container}>
      {typeof prevPagePath === "string" && <BackButton />}
      {typeof currentPagePath === "string" && <ProfileButton />}
    </nav>
  );
}
