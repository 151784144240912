import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar.js";
import ButtonServiceList from "../components/ButtonServiceList.js";
import InputSearchService from "../components/InputSearchService.js";
import NonAuthPage from "./NonAuthPage";
import { useNavigate } from "react-router-dom";
import { textTitleMain } from "../consts/ConstSevicePage";
import { getServicePriceList } from "../functions/api/getServicePriceList.js";
import ButtonServiceData from "../components/ButtonServiceData.js";
import InputSelectTag from "../components/InputSelectTag.js";

const ServicePage = ({ authStatus }) => {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;
  const navigate = useNavigate();
  // видимость кнопок скролла
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [isScrollDownVisible, setIsScrollDownVisible] = useState(true);

  const localStorageServiceList = localStorage.getItem("easehow_serviceList");
  let defaultServiceList = localStorageServiceList
    ? JSON.parse(localStorageServiceList)
    : process.env.NODE_ENV === "development"
    ? ButtonServiceData
    : {};
  const [serviceList, setServiceList] = useState(defaultServiceList);
  const [languageList, setLanguageList] = useState(["ru"]);
  const [selectedTagList, setSelectedTagList] = useState([]);

  useEffect(() => {
    if (authStatus === "success") {
      (async () => {
        let flatTagList = selectedTagList.join(",");
        let serviceList = (await getServicePriceList(flatTagList)).serviceList;
        let languageSet =
          typeof serviceList === "object" &&
          Object.values(serviceList).reduce((acc, service) => {
            Object.keys(service).forEach((lang) => acc.add(lang));
            return acc;
          }, new Set());
        console.log("Array.from(languageSet)", Array.from(languageSet));
        setLanguageList(Array.from(languageSet));

        if (serviceList) {
          localStorage.setItem(
            "easehow_serviceList",
            JSON.stringify(serviceList)
          );
          setServiceList(serviceList);
        }
      })();
    }
  }, [authStatus, selectedTagList]);

  if (authStatus === "wait") {
    // #TODO Экран загрузки
    return <div>...</div>;
  }
  if (authStatus === "noauth") {
    return <NonAuthPage />;
  }

  if (authStatus === "error") {
    navigate("/error");
    return <div></div>;
  }

  let scrollTarget = document.querySelector(".welcome_content");

  function handleScrollButtonUp() {
    let scrollTarget = document.querySelector(".welcome_content");
    scrollTarget.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function handleScrollButtonDown() {
    let scrollTarget = document.querySelector(".welcome_content");
    scrollTarget.scrollTo({
      top: scrollTarget.scrollHeight - scrollTarget.offsetHeight,
      behavior: "smooth",
    });
  }

  function placeScrollbar(scrollTarget) {
    const scrollbar = document.querySelector(".scrollbar");
    const headerHeight = document.querySelector(".header").clientHeight;
    const footerHeight = document.querySelector(".welcome_footer").clientHeight;
    const scrollSize = scrollbar.offsetHeight;
    let scrollPosition =
      scrollTarget.scrollTop /
      (scrollTarget.scrollHeight - scrollTarget.offsetHeight);
    let visibleHeight =
      document.documentElement.clientHeight -
      headerHeight -
      footerHeight -
      scrollSize;

    scrollbar.style.top = `${scrollPosition * visibleHeight}px`;
  }

  // function handleScroll(event) {
  //   placeScrollbar(event.target);
  //   let scrollButtonVisibility = { top: false, bottom: false };
  //   if (event.target.scrollTop <= 20) {
  //     scrollButtonVisibility.bottom = true;
  //   } else {
  //     scrollButtonVisibility.top = true;
  //   }
  //   if (scrollButtonVisibility.top !== isScrollUpVisible) {
  //     setIsScrollUpVisible(scrollButtonVisibility.top);
  //   }
  //   if (scrollButtonVisibility.bottom !== isScrollDownVisible) {
  //     setIsScrollDownVisible(scrollButtonVisibility.bottom);
  //   }
  // }

  console.log("SERVICE PAGE")

  return (
    <div class="container">
      <div className="header">
        <h1>{locale[lang.code].servicePage2}</h1>
      </div>
      <div class="scroll_wrapper">
        {/* <div class="scrollbar"></div> */}
        <InputSelectTag
          tagsArray={languageList}
          onTagChange={(selectedTagArray) => {
            console.log("selectedTagArray", selectedTagArray);
            setSelectedTagList(selectedTagArray);
          }}
        />
        <div class="welcome_content">
          <ButtonServiceList
            serviceList={serviceList}
            tagList={selectedTagList.join(",")}
          />
        </div>
        <InputSearchService />
        <button
          className="tos_button"
          onClick={() => {
            navigate("/tos", {
              state: {
                prevPath: "/",
                prevState: {},
              },
            });
          }}
        >
          {locale[lang.code].servicePage1}
        </button>
      </div>
      <div class="welcome_footer">
        <NavBar currentPagePath={"/"} />
      </div>
    </div>
  );
};

export default ServicePage;
