import styles from "../styles/InputSelectTag.module.css";
import React, { useState, useEffect } from "react";
import { languageNamesTable } from "../consts/LanguageNamesTable.js";

export default function InputSelectTag({ tagsArray, onTagChange }) {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;

  const [isPanelVisible, setPanelVisibility] = useState(false);
  const [isPanelFocused, setPanelFocusStatus] = useState(false);
  const [selectedTagArray, setSelectedTagArray] = useState([]);
  const [visibleTagArray, setVisibleTagArray] = useState(
    tagsArray.map((tag) => tag)
  );
  const [textInputValue, setTextInputValue] = useState("");

  console.log("INPUT SELECT TAG");

  useEffect(() => {
    setVisibleTagArray(tagsArray.map((tag) => tag));
  }, [tagsArray]);

  function toggleTag(tagName) {
    if (selectedTagArray.includes(tagName)) {
      let index = selectedTagArray.indexOf(tagName);
      if (index !== -1) {
        selectedTagArray.splice(index, 1);
      }
    } else {
      selectedTagArray.push(tagName);
    }
    if (typeof onTagChange === "function") {
      onTagChange(selectedTagArray);
    }
    setSelectedTagArray(selectedTagArray.map((item) => item));
  }

  function handleInput(text) {
    if (text === "") {
      setVisibleTagArray(tagsArray.map((tag) => tag));
    } else {
      setVisibleTagArray(tagsArray.filter((tag) => tag.includes(text)));
    }

    setTextInputValue(text);
  }

  let titleText =
    selectedTagArray?.length === 0
      ? locale[lang.code].inputSelectTag1
      : locale[lang.code].inputSelectTag2;

  console.log("visibleTagArray", visibleTagArray);

  return (
    <div className={styles.inputContainer}>
      <p>{titleText}</p>

      <span
        className={styles.inputWrapper}
        tabIndex={0}
        onFocus={(e) => {
          const input = e.target.querySelector("input");
          if (input) {
            input.focus();
          }
        }}
      >
        {selectedTagArray.map((tag) => {
          return (
            <span
              className={styles.selectedTag}
              key={tag}
              onClick={() => {
                toggleTag(tag);
              }}
            >
              {languageNamesTable[tag][lang.code]}
            </span>
          );
        })}
        <input
          placeholder=""
          value={textInputValue}
          onChange={(e) => handleInput(e.target.value)}
          size={textInputValue?.length || 1}
          type="text"
          onFocus={() => {
            setPanelVisibility(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setPanelVisibility(false);
            }, 10);
          }}
        />
      </span>

      <div
        className={styles.tagPanel}
        tabIndex={0}
        style={isPanelVisible || isPanelFocused ? {} : { display: "none" }}
        onClick={(e) => {
          e.target.focus();
        }}
        onFocus={() => {
          setPanelFocusStatus(true);
        }}
        onBlur={() => {
          setPanelFocusStatus(false);
        }}
      >
        <span>{locale[lang.code].inputSelectTag3}</span>
        <ul>
          {visibleTagArray.map((tag) => {
            console.log(`languageNamesTable[${tag}][${lang}]`);
            return (
              <li
                className={
                  selectedTagArray.includes(tag) ? styles.selected : ""
                }
                key={tag}
                onClick={() => {
                  toggleTag(tag);
                }}
              >
                {languageNamesTable[tag][lang.code]}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
