// заявка на поиск недостающего эксперта
import React, { useState, useRef } from "react";
import { sendAnalytics } from "../functions/api/sendAnalytics";
import { AnalyticsData } from "../classes/AnalyticsData";

export default function InputSearchService() {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPlaceholder, setCurrentPlaceholder] = useState(
    locale[lang.code].inputSearchService1
  );
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  let placeholderTimeout = useRef(null);

  function handleKeyPress(event) {
    if (event.key === "Enter" && searchQuery !== "") {
      setCurrentPlaceholder(locale[lang.code].inputSearchService3);

      clearTimeout(placeholderTimeout.current);
      placeholderTimeout.current = setTimeout(() => {
        setCurrentPlaceholder(locale[lang.code].inputSearchService2);
      }, 4000);

      sendAnalytics(
        new AnalyticsData({
          type: "InputSearchService",
          searchQuery: searchQuery,
        })
      );
      setSearchQuery("");
    }
  }

  function handleChange(event) {
    setSearchQuery(event.target.value);
  }

  function handleFocus(event) {
    console.log("Focus!");
    event.target.scrollIntoView({ behavior: "smooth", block: "start" });
    setIsFocused(true);
    setCurrentPlaceholder(locale[lang.code].inputSearchService2);
  }

  function handleBlur() {
    console.log("Blur!");
    setIsFocused(false);
    setCurrentPlaceholder(locale[lang.code].inputSearchService1);
  }

  function handleMouseEnter() {
    setIsMouseOver(true);
    if (!isFocused) {
      setCurrentPlaceholder(locale[lang.code].inputSearchService2);
    }
  }

  function handleMouseLeave() {
    setIsMouseOver(false);
    if (!isFocused) {
      setCurrentPlaceholder(locale[lang.code].inputSearchService1);
    }
  }

  return (
    <div className="input_search_service">
      <input
        type="text"
        placeholder={currentPlaceholder}
        onKeyDown={handleKeyPress}
        value={searchQuery}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
}
