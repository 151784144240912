const ErrorPage = ({}) => {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;
  let textError = {
    "ru": "Упс, что-то пошло не так...",
    "en": "Oops. Something went wrong."
  }
  let textButton = {
    "ru": "Написать админу",
    "en": "«Write to the Admin"
  }
  let style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  };

  return (
    <div style={style}>
      <span>{textError[lang]}</span>
      <button onClick={() => {
        window.Telegram.WebApp.openTelegramLink("https://t.me/easehow");
        window.Telegram.WebApp.close();
      }}>{textButton[lang]}</button>
    </div>
  );
};

export default ErrorPage;
