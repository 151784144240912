import styles from "../styles/ButtonListChangeLocale.module.css";
import React, { useState } from "react";

export default function ButtonListChangeLocale({ handleChange }) {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;

  const [selectedLocale, setSelectedLocale] = useState(
    window.easehow.currentLocale.code
  );

  function buildChangeLocale(localeName) {
    return function () {
      if (window?.easehow?.currentLocale?.code) {
        window.easehow.currentLocale.code = localeName;
        setSelectedLocale(localeName);
        handleChange(localeName);
      }
    };
  }

  return (
    <div>
      <p>{locale[lang.code].buttonListChangeLocale1}</p>
      <ul className={styles.buttonList}>
        <li>
          <button
            onClick={buildChangeLocale("ru")}
            className={`${
              selectedLocale === "ru" ? styles.selectedButton : ""
            }`}
          >
            Русский
          </button>
        </li>
        <li>
          <button
            onClick={buildChangeLocale("en")}
            className={`${
              selectedLocale === "en" ? styles.selectedButton : ""
            }`}
          >
            English
          </button>
        </li>
      </ul>
    </div>
  );
}
