import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import WorkerCard from "../components/WorkerCard";
import styles from "../styles/WorkerPage.module.css";
import NavBar from "../components/NavBar";
import { sendAnalytics } from "../functions/api/sendAnalytics";
import { AnalyticsData } from "../classes/AnalyticsData";
import { textPay } from "../consts/ConstSevicePage";
import ImageItem from "../components/ImageItem";
import VideoItem from "../components/VideoItem";
import ButtonListSelectWorkerLanguage from "../components/ButtonListSelectWorkerLanguage";

const WorkerPage = ({}) => {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;

  const backendDomain = process.env.REACT_APP_BACKEND_DOMAIN;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { workerData, serviceName, tagList, prevState } = state;
  console.log("workerData", workerData);

  const workerLanguageList = workerData?.description?.descriptionText
    ? Object.keys(workerData?.description?.descriptionText)
    : [];

  const defaultSelectedLanguage = Object.keys(
    workerData?.description?.descriptionText
  ).includes(lang.code)
    ? lang.code
    : Object.keys(workerData?.description?.descriptionText)[0];

  const [selectedWorkerLanguage, selectWorkerLanguage] = useState(
    defaultSelectedLanguage
  );

  let priceFiat =
    workerData.service.find((price) => price.currency === "RUB")
      ?.amountPerHour ?? "wrong";
  let priceCrypt =
    workerData.service.find((price) => price.currency === "USDT")
      ?.amountPerHour ?? "wrong";

  function getSubmitHandler(price, currency) {
    const hoursAmount = 1;
    return async function () {
      let result = await sendAnalytics(
        new AnalyticsData({
          type: "PopupExpertsAreBusy",
          hoursAmount: hoursAmount,
          price: price,
          currency: currency,
          service: serviceName,
          id: workerData._id,
          language: selectedWorkerLanguage,
        })
      );
      if (result) {
        window.Telegram.WebApp.openTelegramLink("https://t.me/easehow");
        window.Telegram.WebApp.close();
      }
    };
  }

  return (
    <div className="container">
      <ButtonListSelectWorkerLanguage
        languageList={workerLanguageList}
        selectedLanguage={selectedWorkerLanguage}
        onLanguageChange={(language) => {
          selectWorkerLanguage(language);
        }}
      />
      <WorkerCard
        name={workerData.nickname}
        priceList={workerData.service}
        desrciptionData={workerData.description}
      />

      {/* <button onClick={getSubmitHandler(priceFiat, "RUB")}>Оплатить RUB</button> */}
      <button onClick={getSubmitHandler(priceCrypt, "USDT")}>{textPay}</button>
      <ul className={styles.mediaList}>
        {workerData?.description &&
          Object.keys(workerData.description?.descriptionMedia).length !== 0 &&
          workerData.description?.descriptionMedia[selectedWorkerLanguage].map(
            (mediaurl) => {
              let fileFormat = mediaurl.split(".").pop();
              const videoFormats = ["mp4", "webm"];
              const imageFormats = ["png", "jpg", "jpeg", "webp"];

              let fileType = videoFormats.includes(fileFormat)
                ? "video"
                : imageFormats.includes(fileFormat)
                ? "image"
                : "unsupported";

              if (fileType === "image") {
                return <ImageItem src={`${backendDomain}${mediaurl}`} />;
              }
              if (fileType === "video") {
                let previewName = mediaurl.split(".");
                previewName.pop();
                const previewSrc = previewName.join(".") + "_preview.png";
                return (
                  <VideoItem
                    src={`${backendDomain}${mediaurl}`}
                    previewSrc={previewSrc}
                  />
                );
              }
            }
          )}
      </ul>
      <div className={styles.descriptionText}>
        <p>
          {workerData?.description?.descriptionText[selectedWorkerLanguage]
            .split("\n")
            .map(function (line, i) {
              return (
                <span key={i}>
                  {line}
                  <br />
                </span>
              );
            })}
        </p>
      </div>
      <button
        className="tos_button"
        onClick={() => {
          navigate("/tos", {
            state: {
              prevPath: "/worker",
              prevState: state,
            },
          });
        }}
      >
        {locale[lang.code].workerPage1}
      </button>

      <nav>
        <NavBar
          currentPagePath={"/worker"}
          currentPathState={state}
          prevPagePath={"/list"}
          prevPageState={prevState}
        />
      </nav>
    </div>
  );
};

export default WorkerPage;
