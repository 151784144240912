import { useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
const TOSPage = ({}) => {
  let locale = window.easehow.locale;
  let lang = window.easehow.currentLocale;

  const { state } = useLocation();
  const { prevPath, prevState } = state;

  if (lang.code === "ru") {
    return (
      <div className="container markdown">
        <p>
          <strong>Easehow</strong>&nbsp;&mdash; это платформа, на&nbsp;которой
          вы&nbsp;можете нанять специалиста для решения широкого спектра задач.
          Специалисты оказывают услуги напрямую клиентам, а&nbsp;роль платформы
          заключается в&nbsp;агрегировании и&nbsp;модерации информации
          о&nbsp;специалистах, а&nbsp;также в&nbsp;защите пользователей
          от&nbsp;мошенничества.
        </p>
        <h3 id="-">Как работает платформа:</h3>
        <ol>s
          <li>
            На&nbsp;платформе размещены анкеты специалистов с&nbsp;описанием
            их&nbsp;навыков, стоимости часа работы и&nbsp;рейтинга успешности
            выполненных сделок.
          </li>
          <li>
            Клиент пополняет баланс, с&nbsp;которого будет списываться
            вознаграждение специалисту за&nbsp;оказанную работу.
          </li>
          <li>
            Клиент выбирает специалиста и&nbsp;назначает время начала работы.
          </li>
          <li>
            Платформа передает специалисту контактные данные клиента для общения
            и&nbsp;замораживает на&nbsp;балансе клиента сумму вознаграждения за
            предстоящий час работы.
          </li>
          <li>
            Клиент и&nbsp;специалист самостоятельно определяют продолжительность
            работы в&nbsp;часах.
          </li>
          <li>
            Если в&nbsp;течение 24&nbsp;часов от&nbsp;клиента не&nbsp;поступает
            жалоб на&nbsp;результат работы, платформа выплачивает специалисту
            вознаграждение, равное сумме за&nbsp;оказанные часы.
          </li>
          <li>
            В&nbsp;случае, если услуга оказана с&nbsp;нарушениями, средства
            возвращаются клиенту или переводятся специалисту после устранения
            недочетов.
          </li>
        </ol>
        <h3 id="-">Размещение анкеты специалиста</h3>
        <p>
          Для размещения анкеты нового специалиста на&nbsp;платформе необходимо
          связаться с&nbsp;администратором.
        </p>
        <p>
          Платформа взимает комиссию в&nbsp;размере&nbsp;15% от&nbsp;стоимости
          часа работы специалиста. Комиссия включена в&nbsp;стоимость, указанную
          в&nbsp;анкете специалиста, и&nbsp;вычитается при выплате
          вознаграждения.
        </p>
        <h3 id="-">Способы оплаты</h3>
        <p>
          Оплата услуг специалистов и&nbsp;вывод средств с&nbsp;баланса
          осуществляется в USDT на&nbsp;базе блокчейна TON.
        </p>
        <h3 id="-">Пополнение и&nbsp;вывод средств</h3>
        <p>
          Для пополнения баланса и&nbsp;вывода средств необходимо запросить
          реквизиты у&nbsp;администратора.
        </p>
        <h3 id="-">Борьба с&nbsp;мошенничеством</h3>
        <p>
          Для размещения анкеты на&nbsp;платформе администрация вправе запросить
          у специалиста информацию для подтверждения навыков, а&nbsp;также
          провести собеседование.
        </p>
        <p>
          При подозрении на&nbsp;мошенничество или иную противоправную
          деятельность необходимо сообщить администратору.
        </p>

        <h3 id="-contact-">
          <strong>Контакты</strong>
        </h3>

        <p>
          По&nbsp;всем вопросам просим обращаться к&nbsp;администратору{" "}
          <a href="https://t.me/easehow">https://t.me/easehow</a>
        </p>
        <NavBar prevPagePath={prevPath} prevPageState={prevState} />
      </div>
    );
  }

  return (
    <div className="container markdown">
      <p>
        <strong>Easehow</strong> is&nbsp;a&nbsp;platform where you can hire
        specialists to solve a&nbsp;wide range of&nbsp;tasks. Specialists
        provide services directly to clients, while the platform&rsquo;s role
        is&nbsp;to&nbsp;aggregate and moderate information about the
        specialists, as&nbsp;well as&nbsp;protect users from fraud.
      </p>
      <h3 id="how-the-platform-works-">How the platform works:</h3>
      <ol>
        <li>
          Specialist profiles are listed on&nbsp;the platform, including
          descriptions of&nbsp;their skills, hourly rates, and success ratings.
        </li>
        <li>
          The client tops up&nbsp;their balance, from which the specialist&#39;s
          compensation will be&nbsp;deducted for the services provided.
        </li>
        <li>
          The client selects a&nbsp;specialist and sets the start time for the
          work.
        </li>
        <li>
          The platform provides the specialist with the client&#39;s contact
          information for communication and holds the client&#39;s balance for
          the upcoming hour of&nbsp;work.
        </li>
        <li>
          The client and specialist independently determine the duration
          of&nbsp;the work in&nbsp;hours.
        </li>
        <li>
          If&nbsp;no&nbsp;complaints are received from the client regarding the
          work within 24&nbsp;hours, the platform releases payment to&nbsp;the
          specialist based on&nbsp;the total hours worked.
        </li>
        <li>
          In&nbsp;the case of&nbsp;service issues, the funds are returned
          to&nbsp;the client or transferred to&nbsp;the specialist once the
          issues have been resolved.
        </li>
      </ol>
      <h3 id="specialist-profile-submission">Specialist profile submission</h3>
      <p>
        To&nbsp;submit a&nbsp;new specialist profile on&nbsp;the platform, you
        must contact the administrator.
      </p>
      <p>
        The platform charges a&nbsp;15% commission on&nbsp;the
        specialist&rsquo;s hourly rate. The commission is&nbsp;included
        in&nbsp;the price listed in&nbsp;the specialist&rsquo;s profile and
        is&nbsp;deducted from the payment made to&nbsp;the specialist.
      </p>
      <h3 id="payment-methods">Payment methods</h3>
      <p>
        Payment for services and withdrawal of&nbsp;funds are made in&nbsp;USDT
        based on the TON blockchain.
      </p>
      <h3 id="balance-top-up-and-withdrawal">Balance top-up and withdrawal</h3>
      <p>
        To&nbsp;top up&nbsp;your balance or&nbsp;withdraw funds, you need
        to&nbsp;request the details from the administrator.
      </p>
      <h3 id="fraud-protection">Fraud protection</h3>
      <p>
        To&nbsp;list a&nbsp;profile on&nbsp;the platform, the administration may
        request information from the specialist to&nbsp;verify their skills and
        may also conduct an&nbsp;interview.
      </p>
      <p>
        If&nbsp;there is&nbsp;any suspicion of&nbsp;fraud or&nbsp;other illegal
        activity, please contact the administrator.
      </p>
      <h3 id="-contact-">
        <strong>Contact</strong>
      </h3>
      <p>
        For all inquiries, please contact the administrator at{" "}
        <a href="https://t.me/easehow">https://t.me/easehow</a>.
      </p>
      <NavBar prevPagePath={prevPath} prevPageState={prevState} />
    </div>
  );
};

export default TOSPage;
